<template>
  <div class="page1">
    <EForm
      :formColumns="formColumns"
      :rowSize="4"
      :optionsBtn="true"
      :actionBtn="false"
      :formData="searchForm"
      ref="form"
      @onSearch="onSearch"
      :searchFlag="true"
      :exportShow="false"
      @getChange="changeSelect"
    >
    </EForm>
    <div class="mt20 fs15">
      <!-- 共{{total.totalFeeNum}}笔，合计{{total.totalAmount}}元 -->
      <span class="pl10"> 收{{ total.receiveFeeNum }}笔，合计{{ total.receiveAmount }}元 </span>
      <span class="pl10">
        <!-- 退{{total.returnFeeNum}}笔，合计{{total.returnAmount}}元 -->
      </span>
    </div>
    <ETable
      :tableTitle="tableTitle"
      :tableData="tableData"
      :needPagination="true"
      :count="count"
      @changePage="changePage"
      @changeSize="changeSize"
      :page="searchForm.current"
      :page_size="searchForm.size"
    >
    </ETable>
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import vxRule from '@/assets/js/formValidate';
  import { otherMixin } from '@/components/mixin';

  export default {
    name: 'chargedList',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '合同编号',
            prop: 'contractCode',
          },
          {
            label: '商户名称',
            prop: 'tenantName',
          },
          {
            label: '区域',
            prop: 'areaName',
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode',
          },

          {
            label: '金额',
            prop: 'totalAmount',
          },
          {
            label: '收/退',
            prop: 'dealTypeCn',
          },
          {
            label: '收费时间',
            prop: 'payTime',
          },
          {
            label: '收费人',
            prop: 'chargePersonName',
          },
          {
            label: '退费时间',
            prop: 'returnTime',
          },
          {
            label: '退费人',
            prop: 'returnPerson',
          },
          {
            label: '合同状态',
            prop: 'contractStatusCn',
          },
          {
            label: '合同生效日期',
            prop: 'inDate',
          },
          {
            label: '合同到期日期',
            prop: 'expireDate',
          },
        ],
        tableData: [],
        searchForm: {
          boothCode: null,
          contractCode: null,
          inDate: null,
          expireDate: null,
          chargePersonName: null,
          areaId: null,
          placeId: null,
          tenantName: null,
          contractStatus: null,
          dealType: null, //3/2需求：新增收/退
          current: 1,
          size: 10,
        },
        count: null,
        formColumns: [
          {
            title: '合同编号',
            type: 'text',
            property: 'contractCode',
            show: true,
          },
          {
            title: '摊位号',
            type: 'text',
            property: 'boothCode',
            show: true,
          },
          {
            title: '合同生效时间',
            type: 'datePicker',
            property: 'inDate',
            show: true,
          },
          {
            title: '收费人',
            type: 'text',
            property: 'chargePersonName',
            show: true,
          },
          {
            title: '区域',
            type: 'select',
            property: 'areaId',
            show: true,
            options: [],
          },
          {
            title: '商户名称',
            type: 'text',
            property: 'tenantName',
            show: true,
          },
          {
            title: '合同到期时间',
            type: 'datePicker',
            property: 'expireDate',
            show: true,
          },
          {
            title: '位置',
            type: 'select',
            property: 'placeId',
            show: true,
            options: [],
          },
          {
            title: '合同状态',
            type: 'select',
            property: 'contractStatus',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '执行中',
                value: 1,
              },
              {
                label: '已退租',
                value: 2,
              },
            ],
          },
          {
            title: '收/退',
            type: 'select',
            property: 'dealType',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '退',
                value: 0,
              },
              {
                label: '收',
                value: 1,
              },
            ],
          },
        ],
        total: {
          receiveAmount: 0, //合计金额（收）
          receiveFeeNum: 0, //费用笔数（收）
          returnAmount: 0, //合计金额（退）
          returnFeeNum: 0, //费用笔数（退）
          totalAmount: 0, //总合计金额
          totalFeeNum: 0, //总费用笔数
        },
      };
    },
    watch: {},
    created() {
      this.getAreaLabel();
      this.getData();
    },
    components: { ETable, EDialog, EButton, EForm },
    beforeMount() {},
    methods: {
      changeSelect(prop) {
        if (prop == 'areaId') {
          this.getPlaceLabel();
        }
      },

      onSearch() {
        this.searchForm.current = 1;
        this.getData();
      },
      changePage(page) {
        this.searchForm.current = page;
        this.getData();
      },
      changeSize(size) {
        this.searchForm.size = size;
        this.getData();
      },
      async getData() {
        let res = await Http.getDepositPageList(this.searchForm);
        if (res.code == 200) {
          this.tableData = res.data.page.records;
          this.count = res.data.page.total;
          this.total = {
            receiveAmount: res.data.receiveAmount, //合计金额（收）
            receiveFeeNum: res.data.receiveFeeNum, //费用笔数（收）
            returnAmount: res.data.returnAmount, //合计金额（退）
            returnFeeNum: res.data.returnFeeNum, //费用笔数（退）
            totalAmount: res.data.totalAmount, //总合计金额
            totalFeeNum: res.data.totalFeeNum, //总费用笔数
          };
        }
      },
      //获取位置下拉
      async getPlaceLabel() {
        let res = await Http.getPlaceLabel({
          areaId: this.searchForm.areaId,
        });
        if (res.code == 200) {
          this.formColumns[7].options = res.data;
        }
      },
      //获取区域下拉
      async getAreaLabel() {
        let res = await Http.getAreaLabel({
          status: null,
        });
        if (res.code == 200) {
          this.formColumns[4].options = res.data;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.confirm-btn {
  text-align: right;
}
</style>
